.datePicker {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.datePicker__display {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 8px;
}

.datePicker__calendar {
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #E3E7EF;
  background-color: white;
  border-radius: 8px;
}

.datePicker__calendarHeader {
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;
  border-bottom: 1px solid #E3E7EF;
}

.datePicker__calendarHeader_select {
  border: 1px solid #E3E7EF;
  border-radius: 4px;
  padding: 0px 8px 0px 8px;

  &:focus-visible {
    outline: none;
  }
}

.datePicker__calendarDays {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(1, 1fr);
  gap: 5px;
  grid-auto-flow: row;
  padding: 4px;
}

.datePicker__calendarWeek {
  display: flex;
}

.datePicker__calendarDay {
  text-align: center;
  cursor: pointer;
  width: 33px;
  height: 30px;
}

.datePicker__calendarDay_name {
  text-align: center;
  width: 33px;
  height: 30px;
  color: #343B4C;
}

.datePicker__calendarDay_currentMonth {
  color: #343B4C;
}

.datePicker__calendarDay_selected {
  background-color: #eee;
}