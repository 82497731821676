.footer {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding: 32px 0;
  background: #ffffff;

  button {
    width: 120px;
  }

  .auto_width_btn {
    width: auto;
  }
}

.footer.shadow {
  box-shadow: 0px -8px 24px 0px #1f222a0d;
}
