@import "src/styles";

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: #f0f3fa;
  border-radius: 8px;
}

.accordion {
  cursor: pointer;
  padding: 13px 16px 13px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  user-select: none;
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.6s ease;
}

.text {
  padding: 4px 16px 12px 16px;
  font-weight: 400;
  font-size: 16px;
  color: $color-grey-light;
  line-height: 24px;
}
