@import "src/styles";

.slide_container {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow-y: overlay;
  transition: all 0.3s linear;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px;
}

article::-webkit-scrollbar {
  width: 8px;
}

article::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #0000003d;
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

article.lastSlide {
  transform: translateX(-100%);
}

article.nextSlide {
  transform: translateX(100%);
}

.image {
  height: 400px;
  border: 1px solid #e3e7ef;
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
  }
}

.tutorial_text {
  font-size: 16px;
  line-height: 24px;
}

.info_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.info_item {
  display: flex;
  gap: 16px;
  align-items: center;
  line-height: 24px;
}

.info_svg {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  border-top: 1px solid #e3e7ef;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 88px;
  display: flex;
  align-items: center;
  gap: 24px;
  background: #ffffff;
  padding: 24px;
  user-select: none;
}

.count {
  flex-grow: 1;
  text-align: right;
}

.footer_buttons {
  display: flex;
  gap: 12px;
}

.button_item {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #e3e7ef;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 8px;
    height: 12px;

    path {
      fill: $color-grey;
    }
  }
}

.first_slide {
  svg {
    path {
      fill: #8089a2;
    }
  }
}

.last_slide {
  background: #2ab6a5;

  svg {
    width: 16px;
    height: 12px;

    path {
      fill: #ffffff;
    }
  }
}
