.card {
  height: 100%;
  border-radius: 8px;
  border: 1px solid #e3e7ef;
  padding: 64px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: #e3e7ef;
  position: relative;
  overflow: hidden;
  flex: 0 0 auto;

  img {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
  }
}

.card_name {
  color: #1f222a;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
}

.card_link {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  div {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
