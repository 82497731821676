.modal_title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: inline;

    p {
      color: #1f222a;
    }
  }
}

.cross_wrapper {
  height: 24px;
  width: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
