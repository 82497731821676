.loader {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #8089a2;
  box-shadow: 10px 0 #8089a2, -10px 0 #8089a2;
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: #8089a260;
    box-shadow: 10px 0 #8089a240, -10px 0 #8089a2;
  }
  50% {
    background-color: #8089a2;
    box-shadow: 10px 0 #8089a260, -10px 0 #8089a260;
  }
  100% {
    background-color: #8089a260;
    box-shadow: 10px 0 #8089a2, -10px 0 #8089a240;
  }
}
