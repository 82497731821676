.layout {
  padding-left: 248px;
  padding-top: 64px;
  overflow-x: hidden;
  scrollbar-width: none;
  height: 100%;
  min-height: 100%;
  transition: all 0.3s ease;
}

.layout.wide {
  padding-left: 64px;
}

.layout::-webkit-scrollbar {
  width: 0;
}
