@import "src/styles";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.container {
  display: flex;
  gap: 24px;
}

.input_container {
  width: 100%;
  height: 40px;
  border: 1px solid #e3e7ef;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #FFFFFF;

  input {
    flex-grow: 1;
    border: none;
    font-size: 16px;
    line-height: 16px;
    color: #000;
  }

  :focus {
    outline: none;
  }

  :-webkit-autofill,
  :-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
  }

  ::placeholder {
    color: $color-grey-light;
    font-size: 16px;
    line-height: 16px;
  }
}

.input_container.disabled {
  background: #f0f3fa;

  input {
    color: #aab0c1;
  }

  ::placeholder {
    color: #aab0c1;
  }
}

.input_container.small {
  height: 32px;
  padding: 8px 16px;

  input {
    font-size: 14px;
    line-height: 14px;
  }

  ::placeholder {
    font-size: 14px;
    line-height: 14px;
  }
}

.input_container.error {
  border: 1px solid $color-red;
}

.input_container.table_input {
  background: #F0F3FA;
}

.password {
  position: relative;
}

.image_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
}

.calendar {
  display: flex;
  gap: 24px;
  align-items: flex-end;

  .input_container {
    padding: 14px 16px;
    height: 44px;
    width: 360px;
  }

  .checkbox {
    padding: 12px 0;
  }
}

.label {
  color: $color-grey;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.checkbox_wrapper {
  display: flex;
}

.custom_checkbox {
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 2;
  opacity: 0;
}

.custom_checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.custom_checkbox + label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 12px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 65% 65%;
}

.custom_checkbox:checked + label::before {
  border-color: transparent;
  background-color: #2ab6a5;
  background-image: url("/images/svg/check/check.svg");
}

.radio_wrapper {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  gap: 12px;
}

.radio_container {
  display: flex;
  gap: 24px;
}

.radio_container input[type="radio"] {
  position: absolute;
  opacity: 0;
  left: 0;
}

.radio_container .radio_label {
  display: block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  user-select: none;
  color: $color-grey-light;
}

.radio_container span {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #aab0c1;
  border-radius: 50%;
  transition: all 0.1s ease;
}

.radio_container .radio_label input:checked ~ span {
  border: 5px solid #2ab6a5;
}
.radio_container .radio_label input:checked + span {
  color: #2ab6a5;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("/images/svg/calendar/calendar.svg") no-repeat 100% 50%;
  cursor: pointer;
}

.input_container input[type="date"] {
  font-family: inherit;
}

.modal_input_container {
  padding: 14px 16px;
  height: 44px;
}

.date_wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.date_checkbox {
  height: 100%;
  display: flex;
  align-items: center;
}

.search_icon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
