@import "src/styles";

.main_table_container {
  background: #ffffff;
  border: 1px solid #e3e7ef;
  border-radius: 8px;
  padding: 8px 4px 8px 16px;
  min-width: 100%;
  max-width: 100%;
  width: max-content;
}

.main_table_container_container {
  max-height: 75vh;
  height: max-content;
  overflow: scroll;
  padding-right: 4px;
}

.main_table_container table {
  width: 100%;
}

.main_table_container thead th {
  height: 48px;
}

.inner_head_cell {
  height: 18px;
  display: flex;
  align-items: center;
  border-right: 0.5px solid #e3e7ef;
  justify-content: space-between;
  padding: 0 16px;
  user-select: none;
}

tr {
  border-bottom: 1px solid #e3e7ef;
}
tr:after{
  content:'';
  position:absolute;
  left: 0;
  bottom: 0;
  width:100%;
  border-bottom: 1px solid #e3e7ef;
}

tbody tr:after{
  border: none;
}

thead {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

tr.checked {
  background: #f0f3fa;
}
tr.isChild {
  background: #f0f3fa;
}
tr.lastViewedDocument {
  background-color: #f0f3fa;
}

tr.finded td:first-child:before{
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  border-left: 2px solid #e8363d;
}

thead tr th {
  font-size: 14px;
  font-weight: 500;
  color: #8089a2;
  white-space: nowrap;
}

thead tr th:last-child {
  .inner_head_cell {
    border-right: none;
  }
}

.main_table_container tbody tr {
  cursor: pointer;
  user-select: none;
}

.main_table_container tbody tr:hover {
  background: #f0f3fa;
  transition: all 0.3s ease;
}

.main_table_container tbody .tr__withoutHover:hover {
  background: none;
  cursor: initial;
}

.main_table_container tbody tr td {
  padding: 16px;
  line-height: 20px;
  max-width: 320px;
    position: relative;
  }
  
    .main_table_container {
      tbody {
        tr.errorLzk {
          background-color: #FDF1F1;
  
          &:hover {
            background-color: #FAD7D8;
          }
  
          &.checked {
            background-color: #FAD7D8;
          }
        }
  
        tr.errorLzk td.changeable {
          background-color: #FDF1F1;
        }
  
        tr.errorLzk td.quantity-in-document {
          background-color: #FDF1F1;
        }
  
        tr.errorLzk td.changeable input.input_date {
          background-color: #F0F3FA;
        }
  
        tr.errorLzk:hover td.changeable,
        tr.errorLzk.checked td.changeable {
          background-color: #FAD7D8;
        }
  
        tr.errorLzk:hover td.quantity-in-document,
        tr.errorLzk.checked td.quantity-in-document {
          background-color: #FAD7D8;
    }
  
    tr.reverse {
      background-color: #FDF1F1;

      &:hover {
        background-color: #FAD7D8;
      }
      &.checkedAdditionalRequest {
        background-color: #FAD7D8;
      }
      &.reverseCheked {
        background-color: #F0F3FA;

        &:hover {
          background-color: #F0F3FA;
        }
      }
    }
  }
}

td.quantity-in-document {
  background: #f0f3fa;
}

td.quantity-in-document input {
  background: #f0f3fa;
}

td.changeable, td.changeable input:not(.input_date) {
  background: #F0F3FA;
}

td.indemnityLetter-idCell{
  width: 48px;
}
