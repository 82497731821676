.sort_container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sort_container.asc path:first-child {
  fill: #5c657e;
}
.sort_container.desc path:last-child {
  fill: #5c657e;
}
