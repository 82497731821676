@import "src/styles";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.container {
  width: 100%;
  height: 40px;
  border: 1px solid #e3e7ef;
  border-radius: 6px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;

  select {
    cursor: pointer;
    padding: 9px 12px 9px 16px;
    flex-grow: 1;
    border: none;
    font-size: 16px;
    line-height: 16px;
    color: $color-grey-light;
    font-weight: 400;
    background: inherit;

    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
  }

  :focus {
    outline: none;
  }

  .image_wrapper {
    position: absolute;
    right: 12px;
    display: flex;
    pointer-events: none;
  }
}

.container.error {
  border: 1px solid $color-red;
}

.label {
  color: $color-grey;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
