@import "src/styles";

body {
  height: 100%;
  margin: 0;
  font-family: "Euclid Circular A";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f8fc;
  color: $color-grey;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  width: 0;
}

.main_layout {
  height: 100vh;
  width: 100vw;
}

button {
  font-family: inherit;
  cursor: pointer;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  padding: 13px 24px;
  border: none;
  height: 40px;
}

input,
select,
textarea {
  font-family: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  gap: 24px;
}
