@import "src/styles";

@font-face {
  font-family: "Euclid Circular A";
  src: url("../public/fonts/EuclidCircularA-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Euclid Circular A";
  src: url("../public//fonts/EuclidCircularA-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: medium;
}
@font-face {
  font-family: "Euclid Circular A";
  src: url("../public//fonts/EuclidCircularA-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: bold;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #cbd0de;
  }
}

:focus {
  outline: none;
}

a.active {
  background: #fdf1f1;
  color: #e8363d;

  svg {
    fill: #e8363d;
  }
}

.tab-link-item {
  a {
    display: inline-block;
    padding: 8px 16px;
  }
}

.active-tab-link {
  color: #e8363d;
  border-bottom: 2px solid #e8363d;
}

.tooltip span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 32;
}

.tooltip_content{
  padding: 16px 20px;
}

.tooltip_arrow {
  left: calc(100% - 17px) !important
}

.custom_select,
.custom_select_error {
  &__placeholder {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 16px !important;
    color: $color-grey-light !important;
  }

  &__control {
    border-radius: 6px !important;
    background-color: transparent !important;
    border: 1px solid #e3e7ef !important;
    box-shadow: none !important;

    &--menu-is-open {
      border-color: #e3e7ef !important;
    }
  }

  &__clear-indicator {
    display: none !important;
  }

  &__value-container {
    padding: 4px !important;
    padding-left: 16px !important;
  }

  &__indicator-separator {
    display: none !important;
  }

  &__dropdown-indicator {
    svg {
      path {
        fill: #2ab6a5 !important;
      }
    }
  }

  &__multi-value__remove {
    &:hover {
      background-color: transparent !important;
      color: black !important;
    }
  }

  &__option {
    cursor: pointer !important;
    &--is-focused {
      background-color: #ebeef5 !important;
    }
  }
}

.custom_select_error {
  &__control {
    border: 1px solid $color-red !important;

    input {
      color: $color-red !important;
    }
  }
}

.images-list-row {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 8px;
}

.Toastify__toast-container:has(.notify__class){
  position: absolute;
  font-size: 16px;
  left: calc(50vw - 340px/2);
  top: calc(50vh - 44px/2);

  .notify__class{
    border-radius: 8px;
    padding: 12px 16px 12px 16px;
    width: 340px;
    height: 44px;
    color: #FFFFFF;
    background-color: #343B4CBF;
    min-height: fit-content;

    .Toastify__toast-body{
      margin: 0;
      line-height: 20px;
      padding: 0;
    }

  }

  .Toastify__close-button{
    display: none;
  }

}

.cdx-input.image-tool__caption {
  display: none !important;
}


.modal-wrapper div.modalContent{
  border-radius: 8px;
  .close-wrapper{
    display: none;
  }

  .modalError{
    width: 280px;
    height: 144px;
    display: flex;
    padding: 32px 24px 24px 24px;
    .text{
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      text-align: center;
      margin: 0 auto 24px auto;
    }
    .button{
      width: 232px;
      height: 40px;
    }
  }
}