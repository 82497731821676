.header {
  height: 64px;
  background: #ffffff;
  border-bottom: 1px solid #e3e7ef;
  padding-left: 248px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  transition: all 0.3s ease;
}

.header.wide {
  padding-left: 64px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
}

.content_right {
  display: flex;
  align-items: center;
  gap: 32px;
}

.question {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header_bell {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  div {
    position: absolute;
    top: -35%;
  }
}

.header_person {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #e3e7ef;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.support_wrapper {
  padding: 8px 32px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  border-right: 1px solid #e3e7ef;
}

.support_item {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.logo_wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
