.table_container {
  padding: 8px 16px;
  border: 1px solid #e3e7ef;
  border-radius: 8px;
  background: #ffffff;
}

.table_container table {
  width: 100%;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -khtml-border-radius: 8px;
  border-collapse: collapse;
}

.thead_wrapper {
  width: 100%;
}

.table_container thead th {
  text-align: left;
  height: 48px;
}

.table_container .inner_head_cell {
  display: flex;
  align-items: center;
  border-right: 0.5px solid #e3e7ef;
  padding: 0 16px;
}

tr {
  border-bottom: 1px solid #e3e7ef;
}

tbody > :last-child {
  border-bottom: none;
}

thead tr th {
  font-size: 14px;
  font-weight: 500;
  color: #8089a2;
}

thead tr th:nth-last-child(-n + 2) {
  .inner_head_cell {
    border-right: none;
  }
}

tbody tr td {
  padding: 0 16px;
}

.role_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 8px;
  padding: 11px 0;
}

.role {
  background: #e3e7ef;
  font-size: 14px;
  line-height: 14px;
  padding: 8px 12px;
  border-radius: 6px;
  display: inline;
}

.status_container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown_button {
  width: 40px;
  height: 32px;
  border-radius: 8px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  transition: all 0.5s ease;
}

.dropdown_button:hover {
  background: #f0f3fa;
}
