.link_wrapper {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;

  a {
    text-decoration: none;
    color: #2ab6a5;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}
