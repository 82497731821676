@import "src/styles";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.container {
  display: flex;
  gap: 24px;

  textarea {
    width: 100%;
    height: 240px;
    min-height: 240px;
    border: 1px solid #e3e7ef;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    flex-grow: 1;
    font-size: 16px;
    line-height: 16px;
    color: $color-grey;
  }

  ::placeholder {
    color: $color-grey-light;
    font-size: 16px;
    line-height: 16px;
  }
  :focus {
    outline: none;
  }
}

.label {
  color: $color-grey;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.container.error {
  textarea {
    border: 1px solid $color-red;
  }
}
