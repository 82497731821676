ul {
  list-style: none;
}

.sidebar_wrapper {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  padding: 16px 8px 0 8px;
  border-right: 1px solid #e3e7ef;
  height: 100%;
  width: 248px;
  z-index: 3;
  transition: all 0.3s ease;
}

.sidebar_wrapper.closed {
  width: 64px;
}

.logo_details {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 8px;
  margin-bottom: 16px;
  transition: all 0.3s ease;

  svg {
    flex-shrink: 0;
  }
}

.logo_name {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  flex-grow: 1;
  white-space: nowrap;
}

.sidebar_wrapper.closed .logo_name {
  display: none;
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  scrollbar-width: none;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.nav_links {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  margin-bottom: 100px;
}

.sidebar_wrapper.closed .nav_links {
  overflow: visible;
}

.nav_links > li {
  margin-bottom: 16px;
  position: relative;
}

.nav_links::-webkit-scrollbar {
  width: 0;
}

.item_link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  border-radius: 6px;
  position: relative;
  gap: 12px;
}

.sidebar_wrapper.closed .nav_links li {
  height: 40px;
}

.sidebar_wrapper.closed .nav_links li .item_link {
  padding-left: 12px;
  height: 40px;
}

.sidebar_wrapper.closed .nav_links li .item_link>div {
  width: 100%;
}

.logo_container {
  min-width: 24px;
  min-height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.item .sub_menu {
  border-radius: 6px;
  display: none;
  background-color: #ffffff;
  padding: 4px;
  padding-left: 28px;
  transition: all 0.3s ease;
  z-index: 1;
}

.sidebar_wrapper.closed .nav_links li .sub_menu {
  position: absolute;
  left: calc(100% + 12px);
  top: 0;
  padding-left: 4px;
  white-space: nowrap;
  pointer-events: none;
  display: none;
  margin-top: 0;
  min-width: 220px;
  border: 1px solid #e3e7ef;
}

.sidebar_wrapper.closed .nav_links li:hover .sub_menu {
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.item_link:hover {
  background: #ebeef5;
  cursor: pointer;
}

.link_name {
  flex-grow: 1;
  transition: all 0.2s ease;
  width: 135px;
}

.sidebar_wrapper.closed .link_name {
  opacity: 0;
  pointer-events: none;
}

.sidebar_wrapper .notice {
  position: absolute;
  right: 8px;
}

.sidebar_wrapper.closed .notice {
  position: absolute;
  right: -2px;
  top: -4px;
}

.sub_menu li {
  border-radius: 6px;
  transition: all 0.3s ease;
  overflow: hidden;
}

.sub_menu li a {
  display: block;
  padding: 10px 16px;
}

.sub_menu li:hover {
  background: #ebeef5;
}

.item.open .sub_menu {
  margin-top: 4px;
  display: block;
}

.arrow_wrapper {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: rotate(-90deg);
}

.item.open .arrow_wrapper {
  transform: none;
}

.sidebar_wrapper.closed .arrow_wrapper {
  display: none;
}

.close {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px;
  margin: 0 8px;
  padding: 8px;
  background: #fff;
  border-top: 1px solid #e3e7ef;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
}

.close_logo_container {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.sidebar_wrapper.closed .close_logo_container {
  transform: rotate(180deg);
}
