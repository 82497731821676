@import "src/styles";

.error_container {
  display: flex;
  justify-content: center;
  gap: 8px;
  color: $color-red;
  background: #fdf1f1;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 14px;

  p {
    text-align: center;
  }
}
