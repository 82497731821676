@import "src/styles";

.modal {
  height: 100vh;
  width: 100vw;
  background: #1f222a73;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  backdrop-filter: blur(3px);
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_content {
  width: 640px;
  background: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px 0 0 16px;
  transition: all 0.2s ease;
  padding: 32px;
  padding-bottom: 0;
  overflow: auto;

  button {
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
  }
}

.container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.modalEnterActive {
  opacity: 0;
}
.modalEnterActive .modal_content {
  right: -640px;
}
.modalEnterDone {
  opacity: 1;
  transition: all 0.3s ease;
}
.modalEnterDone .modal_content {
  transition: all 0.3s ease;
  right: 0;
}
.modalExit {
  opacity: 1;
  transition: all 0.3s ease;
}
.modalExit .modal_content {
  right: 0;
  transition: all 0.3s ease;
}
.modalExitActive {
  opacity: 0;
  transition: all 0.3s ease;
}
.modalExitActive .modal_content {
  right: -640px;
  transition: all 0.3s ease;
}

.modal::-webkit-scrollbar,
.modal_content::-webkit-scrollbar {
  width: 0;
}

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

.modal_info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  flex-grow: 1;
  position: relative;
  max-height: 692px;
  overflow-y: scroll;
}

.center_modal_container {
  padding: 32px 24px;
  background: #ffffff;
  border: 1px solid #e3e7ef;
  border-radius: 8px;
  width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}

.center_modal_file_container {
  padding: 24px 0;
  background: #ffffff;
  border: 1px solid #e3e7ef;
  border-radius: 8px;
  width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}

.center_modal_file_element {
  padding: 40px 0 40px 0;
  border-top: 1px solid #E3E7EF;
  border-bottom: 1px solid #E3E7EF;
 
}

.delete_title {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.delete_buttons_container {
  display: flex;
  gap: 8px;

  button {
    width: 50%;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
  }
}

.input_block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

textarea {
  height: 200px;
}

.success {
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.error {
  font-size: 12px;
  line-height: 16px;
  color: $color-red;
  margin-top: 8px;
}

.power_of_attorney {
  display: flex;
  gap: 24px;
  align-items: flex-start;

  .input_container {
    padding: 14px 16px;
    height: 44px;
    width: 360px;
  }

  .checkbox {
    padding: 12px 0;
    height: 44px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.date_wrapper {
  width: 360px;
}

.tutorial_container {
  background: #ffffff;
  width: 688px;
  height: 692px;
  border-radius: 8px;
  padding-bottom: 0;
  border: 1px solid #e3e7ef;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header_wrapper {
  padding: 24px;
}
