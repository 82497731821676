@import "src/styles";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #ffffff;
}

.label {
  color: $color-grey;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.file_input {
  position: relative;
  border: 1px dashed #cbd0de;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.file_input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.file_input_label {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: $color-grey-light;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;

  div {
    display: inline-block;
  }

  span {
    font-size: 12px;
    line-height: 16px;
    color: #aab0c1;
  }
}

.preview {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.preview_item {
  display: flex;
  gap: 16px;
}

.preview_item_info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 38px;
  font-size: 14px;
  line-height: 18px;
  color: $color-grey;
  flex-grow: 1;

  span {
    font-size: 12px;
    line-height: 12px;
    color: #aab0c1;
  }
}

.preview_item_remove {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;

    path {
      fill: #aab0c1;
    }
  }
}

.link_wrapper {
  display: flex;
  padding: 8px 0;
}
